// config for production
export default {
  lbCommunityBaseUrl: 'https://communityapp.learnbody.co.kr',
  lbMarketApiBaseUrl: 'https://marketapi.learnbody.co.kr/',
  lbDiaryApiBaseUrl: 'https://diaryapi.learnbody.co.kr/',
  lbDiaryBaseUrl: 'https://diary.learnbody.co.kr/',
  lbAutocompleteIndexName: 'autocomplete-production',
  lbMarketStorageUrl: 'https://learnbodykr.blob.core.windows.net/market-images/',
  ncpApiBaseUrl: 'https://shop-api.e-ncp.com/',
  ncpApiBaseUrlWebview: 'https://shop-api.e-ncp.com/',
  ncpClientId: 'xRzkr7sOuawIgcDtOgSWSA==',
  payPlusJs: 'https://stdpay.inicis.com/stdjs/INIStdPay.js',
  payJs: 'ncp_pay.js',
  searchJs: 'ncp_search.js',
  cdnUrl: 'https://learnbodykr.blob.core.windows.net/cdn/',
  lbMarketStorageDir: 'market-images',

  paycoAuthorizeUrl: 'https://id.payco.com/oauth2.0/authorize',
  paycoAuthorizeJoinUrl: 'https://id.payco.com/join.nhn',
  paycoClientIdForIOS: 'tr0j040Ns2LlTvmS39rY',
  paycoClientIdForAOS: 'PkRa9CYXfTlEZfB_avZe',
  paycoClientIdForWEB: 'OxwGnaYSbcaRgC2PWLCN',
  paycoCertifyLogin: 'https://id.payco.com/certifyLogin.nhn',
  paycoMyinfo: 'https://id.payco.com/myinfo.nhn',
  paycoChangPwd: 'https://id.payco.com/myInfoChangePwd.nhn',
  oldPingdamall: 'http://old.buywow.co.kr',
  oldPdmall: 'http://old.pdmall.buywow.co.kr',
  homeBannerCode: '000',
  homeMagicBannerCode: '027',
  homeSectionNo: '20680',
  todaySectionNo: '12',
  brandBannerCode: '003',
  eventTabBannerCode: '004',
  categoryBannerCode: '002',
  myPageTopBannerCode: '005',
  myPageBottomBannerCode: '006',
  faqBoardNo: '57044',
  kakaoKey: '73a5703cca8f219fd86af33088e00b44',
  navCagetories: ['79761', '79762', '79769', '79770'],
  
  lbPaycrewBaseUrl: 'https://paycrew.learnbody.co.kr/',
  adropUrl: 'https://api-rest.adrop.io/',
  adropId: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6IjAxSjVXRkdSVEFRS0E1UTBOWFNGTjlIQUdCOjAxSkE5NVYzNVYyTVpOSFhLVDg5OUs5WFczIiwia2V5VHlwZSI6MSwiaWF0IjoxNzI5MDMyNDU3LCJleHAiOjI1MzQwMjEyODAwMH0.trK8dVl2FaBqsBc59FJONYX1PYsB27rqqk38Ry1XYsQ'
}
