import cookies from 'js-cookie'
import { getAdropData, requestGetWithFullURL } from '@/api'

const ENDPOINT_REQUEST = 'request'
const defaultParams = {
  uid: cookies.get('memberCummunityUID'),
  pf: 'web',
  lcl: 'ko-KR'
}

export default {
  namespaced: true,
  state: {
    isActiveFeed: false,
    isActiveMarket: false
  },

  actions: {
    // 광고 유닛 조회
    async getAdropUnitData ({ state }, payload) {
      const resp = await getAdropData(ENDPOINT_REQUEST, 'get', 
        { 
        ...defaultParams,
        ...payload
      })
      return resp
    },
    // 광고 트래커 호출
    async trackingAdrop ({ state }, fullurl) {
      const resp = await requestGetWithFullURL(fullurl)
      return resp
    }
  },
  mutations: {
    SET_ACTIVE_FEED (state) {
      state.isActiveFeed = true
    },
    SET_ACTIVE_MARKET (state) {
      state.isActiveMarket = true
    }
  }
}