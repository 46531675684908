import LBTabMainHeader from '@/components/LBTab/LBTabMainHeader'
import MainHeader from '@/components/header/MainHeader'
import FreshwayHeader from '@/components/header/FreshwayHeader'
import ListByBrandHeader from '@/components/header/ListByBrandHeader'
import ProductDetailHeader from '@/components/header/ProductDetailHeader'
import OrderHeader from '@/components/header/OrderHeader'
import CategoryHeader from '@/components/header/CategoryHeader'
import MypageHeader from '@/components/header/MypageHeader'
import PaymentHeader from '@/components/header/PaymentHeader'
import ErrorHeader from '@/components/header/ErrorHeader'
import TitleHeader from '@/components/header/TitleHeader'
import TopMenu from '@/components/header/TopMenu'
import SiteFooter from '@/components/footer'
import store from '@/store'
import { asyncComponent } from './utils'

function genTitleHeader(title, util) {
  return {
    components: { TitleHeader, TopMenu },
    render(createElement) {
      return createElement('TitleHeader', {
        props: {
          title,
          util,
        },
      })
    },
  }
}

export default [
  /** 운영기간 일경우 주석 */
  // 서비스 점검 페이지
  // {
  //   path: '/*',
  //   name: 'serviceMaintenancePage',
  //   components: {
  //     default: asyncComponent('banner/LBserviceMaintenancePage')
  //   }
  // },
  // {
  //   path: '/*',
  //   name: 'NotHour',
  //   components: {
  //     default: asyncComponent('etc/NotHour')
  //   }
  // },
  /** main */
  {
    path: '/',
    name: 'Main',
    components: {
      default: asyncComponent('LBTab/LBTabMainPage'),
      // default: asyncComponent('main/index'),
      header: LBTabMainHeader,
      // header: MainHeader,
      footer: SiteFooter,
    },
    meta: {
      needAuth: true,
    },
    children: [
      /** main home */
      {
        path: '',
        name: 'MainHome',
        component: asyncComponent('main/home/index'),
        meta: {
          action: true,
          content: 'main',
        },
      },
    ],
  },

  {
    path: '/LBFeedFilter',
    name: 'LBFeedFilter',
    components: {
      default: asyncComponent('LBTab/LBTabFilterPage'),
    },
    meta: {
      needAuth: true,
    },
  },

  {
    path: '/LBFeed',
    name: 'LBFeed',
    components: {
      default: asyncComponent('LBFeed/LBFeedMainTab'),
      header: LBTabMainHeader,
      // footer: SiteFooter,
    },
    meta: {
      needAuth: true,
    },
  },

  {
    path: '/LBFeedFilter',
    name: 'LBFeedFilter',
    components: {
      default: asyncComponent('LBTab/LBTabFilterPage'),
    },
    meta: {
      needAuth: true,
    },
  },

  {
    path: '/LBDiary/LBDiaryMealPage',
    name: 'LBDiaryMealPage',
    components: {
      default: asyncComponent('LBDiary/LBDiaryMealPage'),
    },
    meta: {
      needAuth: true,
    },
  },
  {
    path: '/LBDiary/LBDiaryWorkoutPage',
    name: 'LBDiaryWorkoutPage',
    components: {
      default: asyncComponent('LBDiary/LBDiaryWorkoutPage'),
    },
    meta: {
      needAuth: true,
    },
  },

  /** brand detail */
  {
    path: '/brand/:brandNo',
    name: 'Brand',
    components: {
      default: asyncComponent('brand/index'),
      header: ListByBrandHeader,
      footer: SiteFooter,
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  {
    path: '/LBProfile',
    name: 'LBProfile',
    components: {
      default: asyncComponent('LBProfile/LBProfilePage'),
    },
    meta: {
      needAuth: true,
    },
  },
  {
    path: '/LBProfileEdit',
    name: 'LBProfileEdit',
    components: {
      default: asyncComponent('LBProfile/LBProfileEditPage'),
    },
    meta: {
      needAuth: true,
    },
  },
  {
    path: '/LBSurvey',
    name: 'LBSurvey',
    components: {
      default: asyncComponent('LBSurvey/LBSurveyPage'),
    },
    meta: {
      needAuth: true,
    },
  },
  {
    path: '/LBFeed/LBFeedCommentPage',
    name: 'LBFeedCommentPage',
    components: {
      default: asyncComponent('LBFeed/LBFeedCommentPage'),
    },
    meta: {
      needAuth: true,
    },
  },
  /** product New search */
  // {
  //   path: '/header/searchlayer',
  //   name: 'NewSearch',
  //   components: {
  //     default: asyncComponent('header/SearchLayer'),
  //     footer: SiteFooter
  //   },
  //   meta: {
  //     needAuth: true,
  //     action: true
  //   }
  // },
  /** product New search */
  {
    path: '/LBIntegratedSearch/LBSearchPage',
    name: 'NewSearch',
    components: {
      default: asyncComponent('LBIntegratedSearch/LBSearchPageServiceStop'),
      // footer: SiteFooter
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** dietmeal main page */
  {
    path: '/LBIntegratedSearch/LBDietmealPage/:FilterType?/:Code?',
    name: 'LBDietmealPage',
    components: {
      default: asyncComponent('LBIntegratedSearch/LBDietmealPage'),
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** dietmeal bookmark */
  {
    path: '/LBIntegratedSearch/DietmealBookmark',
    name: 'DietmealBookmark',
    components: {
      default: asyncComponent('LBIntegratedSearch/LBSearchResultDietmealBookmark'),
      // footer: SiteFooter
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** dietmeal bookmark */
  {
    path: '/LBIntegratedSearch/DietmealFilter',
    name: 'DietmealFilter',
    components: {
      default: asyncComponent('LBIntegratedSearch/LBSearchResultDietmealFilter'),
      // footer: SiteFooter
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** product detail */
  {
    path: '/product',
    name: 'ProductInfo',
    components: {
      default: asyncComponent('productDetail/ProductDetail'),
      header: ProductDetailHeader,
      footer: SiteFooter,
    },
    meta: {
      needAuth: true,
    },
    beforeEnter(to, form, next) {
      // if (to.query.preview === 'true' && !document.referrer.match(/.*\.e-ncp.com\/.*/)) {
      //   alert('정상적인 접근이 아닙니다.')
      //   next({ path: '/' })
      // } else {
      //   next()
      // }
      next()
    },
    children: [
      /** product detail content */
      {
        path: ':productId(\\d+)',
        name: 'ProductDetail',
        component: asyncComponent('productDetail/ProductDetailView'),
        meta: {
          keepScroll: true,
          action: true,
          content: 'detail',
        },
      },
      /** product detail inquiry */
      {
        path: 'inquiry/:productId(\\d+)',
        name: 'ProductDetailInquiry',
        component: asyncComponent('productDetail/inquiry/ProductDetailInquiry'),
        meta: {
          keepScroll: true,
          action: true,
        },
      },
      /** product detail information */
      {
        path: 'info/:productId(\\d+)',
        name: 'ProductDetailMoreInfo',
        component: asyncComponent('productDetail/ProductDetailProductInfo'),
        meta: {
          keepScroll: true,
          action: true,
        },
      },
    ],
  },
  /** cart */
  {
    path: '/cart',
    name: 'Cart',
    components: {
      default: asyncComponent('cart/index'),
      // header: genTitleHeader('장바구니'),
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** order */
  {
    path: '/order/getsheetno',
    name: 'BeforeOrder',
    components: {
      default: asyncComponent('order/BeforeOrder'),
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  {
    path: '/order/:orderSheetNo(\\d+)',
    name: 'OrderSheet',
    components: {
      default: asyncComponent('order/OrderSheet'),
      header: OrderHeader,
      footer: SiteFooter,
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** payment result confirm */
  {
    path: '/order/paymentconfirm',
    name: 'PaymentConfirm',
    components: {
      default: asyncComponent('order/PaymentConfirm'),
      header: PaymentHeader,
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** member setting */
  {
    path: '/member/setting',
    name: 'MemberSetting',
    components: {
      default: asyncComponent('member/Setting'),
      header: genTitleHeader('CJ프레시웨이 임직원몰 탈퇴'),
      footer: SiteFooter,
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  {
    path: '/member/bridge',
    name: 'LoginBridge',
    beforeEnter(to, form, next) {
      store.dispatch('loginBridge', {
        trackingKey: to.query.trackingKey,
        redirect: to.query.redirect,
      })
    },
    meta: {
      needAuth: true,
    },
  },
  /** member findid */
  {
    path: '/member/findidpw',
    name: 'Findidpw',
    components: {
      default: asyncComponent('member/Findidpw'),
    },
  },
  /** member findid */
  {
    path: '/member/findconfirm',
    name: 'FindConfirm',
    components: {
      default: asyncComponent('member/FindConfirm'),
    },
    meta: {
      action: true,
    },
  },
  /** member findid */
  {
    path: '/member/findPWconfirm',
    name: 'FindPWconfirm',
    components: {
      default: asyncComponent('member/FindPWconfirm'),
    },
  },
  /** member login */
  {
    path: '/member/login',
    name: 'LBLogin',
    components: {
      default: asyncComponent('member/LBLoginRefresh'),
      header: FreshwayHeader,
    },
  },
  /** member auto login */
  {
    path: '/member/autologin',
    name: 'LBAutoLogin',
    components: {
      default: asyncComponent('member/LBAutoLogin'),
    },
  },
  /** member info modify */
  {
    path: '/member/mbrmodify',
    name: 'MbrModify',
    components: {
      default: asyncComponent('member/MbrModify'),
      header: genTitleHeader('회원정보관리'),
      footer: SiteFooter,
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** no member order search */
  {
    path: '/member/nonemember/ordersearch',
    name: 'OrderSearch',
    components: {
      default: asyncComponent('member/nonemember/OrderSearch'),
      header: genTitleHeader('비회원 주문조회'),
      footer: SiteFooter,
    },
  },
  /** no member order detail */
  {
    path: '/member/nonemember/orderdetail/:orderNo(\\d+)',
    name: 'NoneMemberDetail',
    components: {
      default: asyncComponent('member/nonemember/OrderDetail'),
      footer: SiteFooter,
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** no member password forget  */
  {
    path: '/member/nonemember/pwsearch',
    name: 'PwSearch',
    components: {
      default: asyncComponent('member/nonemember/PwSearch'),
      header: genTitleHeader('비회원 주문 비밀번호 찾기'),
      footer: SiteFooter,
    },
    meta: {
      action: true,
    },
  },
  /** my page order list */
  {
    path: '/mypage/orderlist',
    name: 'OrderList',
    components: {
      default: asyncComponent('mypage/order/list/OrderList'),
    },
    meta: {
      needAuth: true,
      // keepScroll: true,
      action: true,
    },
  },
  /** my page cancel list */
  {
    path: '/mypage/cancellist',
    name: 'CancelList',
    components: {
      default: asyncComponent('mypage/order/list/CancelList'),
    },
    meta: {
      needAuth: true,
      // keepScroll: true,
      action: true,
    },
  },
  /** my page order detail */
  {
    path: '/mypage/orderdetail/:orderNo(\\d+)',
    name: 'OrderDetail',
    components: {
      default: asyncComponent('mypage/order/detail/OrderDetail'),
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** my page like products */
  {
    path: '/mypage/likeproducts',
    name: 'LikeProducts',
    components: {
      default: asyncComponent('mypage/like/likeProducts'),
      // header: genTitleHeader('찜한상품')
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** my page my coupon */
  {
    path: "/mypage/mycoupon",
    name: "MyCoupon",
    components: {
      default: asyncComponent('mypage/coupon/myCoupon'),
    },
    meta: {
      needAuth: true,
      action: false,
    },
    children: [
      {
        path: '/',
        name: 'ValidCoupon',
        component: asyncComponent('mypage/coupon/validCoupon'),
        meta: {
          keepScroll: true,
          action: true,
        },
      },
      {
        path: 'novalidcoupon',
        name: 'NoValidCoupon',
        component: asyncComponent('mypage/coupon/noValidCoupon'),
        meta: {
          keepScroll: true,
          action: true,
        },
      },
    ],
  },
  /** my page regist coupon */
  {
    path: '/mypage/registcoupon',
    name: 'RegistCoupon',
    components: {
      default: asyncComponent('mypage/coupon/registCoupon'),
      header: genTitleHeader('쿠폰코드 등록'),
      footer: SiteFooter,
    },
    meta: {
      needAuth: true,
    },
  },
  /** my page accumulations */
  {
    path: '/mypage/accumulations',
    name: 'Accumulations',
    components: {
      default: asyncComponent('mypage/accumulation/Index'),
      // header: genTitleHeader('적립금')
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** my page return order */
  {
    path: '/mypage/return/:orderOptionNo(\\d+)',
    name: 'Return',
    components: {
      default: asyncComponent('mypage/order/claim/Return'),
      header: genTitleHeader('반품신청'),
      footer: SiteFooter,
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** my page return detail */
  {
    path: '/mypage/returndetail/:claimNo(\\d+)',
    name: 'ReturnDetail',
    components: {
      default: asyncComponent('mypage/order/claim/ReturnDetail'),
      header: genTitleHeader('반품 상세정보'),
      footer: SiteFooter,
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** my page cancel detail */
  {
    path: '/mypage/canceldetail/:orderOptionNo(\\d+)',
    name: 'CancelDetail',
    components: {
      default: asyncComponent('mypage/order/claim/CancelDetail'),
      header: genTitleHeader('취소 상세정보'),
      footer: SiteFooter,
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** my page cancel */
  {
    path: '/mypage/cancel/:orderOptionNo(\\d+)',
    name: 'Cancel',
    components: {
      default: asyncComponent('mypage/order/claim/Cancel'),
      footer: SiteFooter,
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** no member return order */
  {
    path: '/nonemember/return/:orderOptionNo(\\d+)',
    name: 'NoneMemberReturn',
    components: {
      default: asyncComponent('member/nonemember/NoneMemberReturn'),
      header: genTitleHeader('반품신청'),
      footer: SiteFooter,
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** no member cancel detail */
  {
    path: '/nonemember/canceldetail/:orderOptionNo(\\d+)',
    name: 'NoneMemberCancelDetail',
    components: {
      default: asyncComponent('member/nonemember/NoneMemberCancelDetail'),
      header: genTitleHeader('취소 상세정보'),
      footer: SiteFooter,
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** no member return detail */
  {
    path: '/nonemember/returndetail/:claimNo(\\d+)',
    name: 'NoneMemberReturnDetail',
    components: {
      default: asyncComponent('member/nonemember/NoneMemberReturnDetail'),
      header: genTitleHeader('반품 상세정보'),
      footer: SiteFooter,
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** no member cancel */
  {
    path: '/nonemember/cancel/:orderOptionNo(\\d+)',
    name: 'NoneMemberCancel',
    components: {
      default: asyncComponent('member/nonemember/NoneMemberCancel'),
      header: genTitleHeader('취소 상세정보'),
      footer: SiteFooter,
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** faq */
  {
    path: '/etc/faq',
    name: 'Boards',
    components: {
      default: asyncComponent('etc/Boards'),
      header: genTitleHeader('자주하는 질문'),
      footer: SiteFooter,
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** shop_list */
  {
    path: '/etc/shoplist',
    name: 'ShopList',
    components: {
      default: asyncComponent('etc/ShopList'),
      header: genTitleHeader('개인정보 제공 판매업체 리스트'),
      footer: SiteFooter,
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** agree */
  {
    path: '/etc/agree',
    name: 'AgreeV02',
    components: {
      default: asyncComponent('etc/Agree'),
      header: genTitleHeader('이용약관'),
      footer: SiteFooter,
    },
    meta: {
      needAuth: true,
    },
  },
  {
    path: '/etc/dataerror',
    name: 'DataError',
    components: {
      default: asyncComponent('etc/DataError'),
      header: ErrorHeader,
      footer: SiteFooter,
    },
  },
  {
    path: '/etc/construction',
    name: 'Construction',
    components: {
      default: asyncComponent('etc/Construction'),
      header: ErrorHeader,
    },
  },
  {
    path: '/logincallback',
    name: 'LoginCallback',
    beforeEnter(to, form, next) {
      store.dispatch('saveLogin', {
        code: to.query.code,
        token: to.query.token,
        nxturl: to.query.nxturl,
      })
    },
  },
  {
    path: '/paycocertify',
    name: 'PaycoCertify',
    beforeEnter(to, form, next) {
      store.dispatch('paycoCertify', { nxturl: to.query.nxturl })
    },
  },
  {
    path: '/tokenerror',
    name: 'TokenError',
    components: {
      default: asyncComponent('etc/TokenError'),
    },
  },
  /** 최저가핫딜 */
  {
    path: '/lbevent/lbhotdealpage',
    name: 'LBHotdeal',
    components: {
      default: asyncComponent("lbEvent/LBHotdealPage.vue"),
      // header: MainHeader,
      footer: SiteFooter,
    },
    meta: {
      needAuth: true,
      action: false,
    },
  },
  {
    path: '/lbevent/lbhotdealitempage/:hotDealNo',
    name: 'LBHotdealitem',
    components: {
      default: asyncComponent('lbEvent/LBHotdealItemPage.vue'),
      // header: MainHeader,
      footer: SiteFooter,
    },
    meta: {
      needAuth: true,
      action: false,
    },
  },
  /** 다이어트 신상 */
  {
    path: '/lbEvent/lbnewproductpage',
    name: 'LBNewProduct',
    components: {
      default: asyncComponent("lbEvent/LBNewProductPage.vue"),
      // header: MainHeader,
      footer: SiteFooter,
    },
    meta: {
      needAuth: true,
      action: false,
    },
  },
  /** 친구 초대 */
  {
    path: '/lbinvite/lbinvitepage',
    name: 'LBInvite',
    components: {
      default: asyncComponent('LBInvite/LBInvitePage.vue'),
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** 카테고리 페이지 */
  {
    path: '/tab/lbcategorypage',
    name: 'LBCategoryPage',
    components: {
      default: asyncComponent('tab/LBCategoryPage.vue'),
      header: CategoryHeader,
      // footer: SiteFooter
    },
    meta: {
      needAuth: true,
      action: false,
    },
  },
  /** 마이페이지 */
  {
    path: '/tab/lbmypage',
    name: 'LBMyPage',
    components: {
      default: asyncComponent('tab/LBMyPage.vue'),
      header: MypageHeader,
    },
    meta: {
      needAuth: true,
      action: false,
    },
  },
  /**  카테고리 리스트 */
  {
    path: '/productList/lbproductlist',
    name: 'LBProductList',
    components: {
      default: asyncComponent('productList/LBProductList.vue'),
      // header: MainHeader,
      // footer: SiteFooter
    },
    meta: {
      needAuth: true,
      action: false,
    },
  },
  /** 서브 카테고리 리스트 */
  {
    path: '/productList/lbproductsublist',
    name: 'LBProductSubList',
    components: {
      default: asyncComponent('productList/LBProductSubList.vue'),
      // header: MainHeader,
      // footer: SiteFooter
    },
    meta: {
      needAuth: true,
      action: false,
    },
  },
  /** 검색 후 리스트 */
  {
    path: '/LBSearch/lbsearchproductlist',
    name: 'LBSearchProductList',
    components: {
      default: asyncComponent('LBSearch/LBSearchProductList.vue'),
    },
    meta: {
      needAuth: true,
      action: false,
    },
  },
  // 전체 상품 페이지
  {
    path: '/productList/lbfilteredproductlist',
    name: 'LBFilteredProductList',
    components: {
      default: asyncComponent('productList/LBFilteredProductList.vue'),
      // header: MainHeader,
      // footer: SiteFooter
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** 성공스토리 */
  {
    path: '/dieterStory/:postID(\\d+)',
    name: 'DieterStory',
    components: {
      default: asyncComponent('LBDieterStory/index'),
      // header: genTitleHeader('성공스토리'),
      footer: SiteFooter,
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** 뭐먹지 */
  {
    // path: '/dietMealDetail/:postID(\\d+)',
    path: '/dietMealDetail',
    name: 'DietMealDetail',
    components: {
      default: asyncComponent('LBCommunity/dietMealDetail.vue'),
      // header: genTitleHeader('성공스토리'),
      // footer: SiteFooter
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  // 뭐먹지 대체식품
  {
    path: '/dietMealReplaceMent/:foodCode',
    name: 'dietMealReplaceMent',
    components: {
      default: asyncComponent('LBCommunity/dietMealReplaceMent.vue'),
      // header: genTitleHeader('성공스토리'),
      // footer: SiteFooter
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** 마켓 설명 페이지 */
  {
    path: '/tab/lbmarketdescription',
    name: 'MarketDescription',
    components: {
      default: asyncComponent('tab/LBMarketDescription'),
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** 커뮤니티 컨텐츠 토탈 리스트 페이지 */
  {
    path: '/lbcommunity/communitytotallist',
    name: 'CommunityTotalList',
    components: {
      default: asyncComponent('LBCommunity/communityTotalList.vue'),
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** 리뷰작성 페이지 */
  {
    path: '/lbreview/lbreviewwrite',
    name: 'WriteReview',
    components: {
      default: asyncComponent('LBReview/LBReviewWrite.vue'),
    },
    meta: {
      needAuth: true,
    },
  },
  /** 리뷰전체보기 페이지 */
  {
    path: '/lbreview/lbreviewpage',
    name: 'ReviewPage',
    components: {
      default: asyncComponent('LBReview/LBReviewPage.vue'),
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** 비어있는 풀 팝업 */
  {
    path: '/tab/lbemptyfullpage/:type?/:typeValue?',
    name: 'EmptyFullPagePopup',
    components: {
      default: asyncComponent('tab/LBEmptyFullPage'),
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** 본인인증 */
  {
    path: '/member/Certification/:returnUrl?',
    name: 'Certification',
    components: {
      default: asyncComponent('member/LBCertification'),
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /* 필터 */
  {
    path: '/LBFilter/LBFilterPage/:pageCode?/:categoryNo?/:landingType?',
    name: 'ProductListFilter',
    components: {
      default: asyncComponent('LBFilter/LBFilterPage'),
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** 쿠폰 다운로드 */
  {
    path: '/LBCoupon/LBCouponPage',
    name: 'CouponPage',
    components: {
      default: asyncComponent('LBCoupon/LBCouponPage'),
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** 이벤트 적립금 페이지 */
  {
    path: '/LBReward/LBRewardEventPage',
    name: 'RewardEventPage',
    components: {
      default: asyncComponent('LBReward/LBRewardEventPage'),
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** 따라사기 버전 성공스토리  */
  {
    path: '/LBImitateOrder/LBIODieterStory',
    name: 'LBIODieterStory',
    components: {
      default: asyncComponent('LBImitateOrder/LBIODieterStory'),
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** 따라사기 버전 성공스토리 v2  */
  {
    path: '/LBImitateOrder/LBIODieterStoryV2',
    name: 'LBIODieterStoryV2',
    components: {
      default: asyncComponent('LBImitateOrder/LBIODieterStoryV2'),
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** 따라사기 식단 상세 페이지  */
  {
    path: '/LBImitateOrder/LBIOMealDetail',
    name: 'LBIOMealDetail',
    components: {
      default: asyncComponent('LBImitateOrder/LBIOMealDetail'),
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** 성공러 식단 페이지 */
  {
    path: '/LBImitateOrder/LBIODieterCategories',
    name: 'LBIODieterCategories',
    components: {
      default: asyncComponent('LBImitateOrder/LBIODieterCategories'),
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** 자주 먹는 식품 따라사기 페이지  */
  {
    path: '/LBImitateOrder/LBIOFrequentlyEatenFood',
    name: 'LBIOFrequentlyEatenFood',
    components: {
      default: asyncComponent('LBImitateOrder/LBIOFrequentlyEatenFood'),
    },
    meta: {
      needAuth: true,
      action: true,
    },
  },
  /** 마켓 메인 */
  {
    path: "/LBMarket",
    name: "LBMarket",
    components: {
      default: asyncComponent("LBMarket/LBMarketMainTab"),
      header: LBTabMainHeader,
      footer: SiteFooter,
    },
    meta: {
      needAuth: true,
    },
  },
  /**캠페인 상세 */
  {
    path: "/LBFeed/LBFeedCampaignPage",
    name: "LBFeedCampaignPage",
    components: {
      default: asyncComponent("LBFeed/LBFeedCampaignPage"),
    },
    meta: {
      needAuth: true,
    },
  },
  /** 크루모집 메인 */
  {
    path: '/LBCrewApply',
    name: 'LBCrewApply',
    components: {
      default: asyncComponent('LBCrew/LBCrewApply'),
      header: LBTabMainHeader,
      // footer: SiteFooter,
    },
    meta: {
      needAuth: true,
    },
  },
  /** page not find */
  {
    path: '/*',
    name: '404',
    components: {
      default: asyncComponent('etc/PageNotFound'),
      header: ErrorHeader,
      footer: SiteFooter,
    },
  },
]
