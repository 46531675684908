
import { getLBData } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT_MAINAREAORDER = 'Member/ADTestMainAreaInfo'

export default {
  namespaced: true,
  state: {
    mainAreaOrder: {}
  },
  actions: {
    async getMainAreaOrder ({ state, commit, dispatch }) {
      const resp = await getLBData(ENDPOINT_MAINAREAORDER, 'get', { MallAccessToken: cookies.get('ncpAccessToken') })
      state.mainAreaOrder = resp.data
      // console.log(resp.data)

      // 광고 추가
      if (state.mainAreaOrder?.mainAreaCodeList && state.mainAreaOrder?.mainAreaCodeList.length > 0) {
        // 상단 이미지 광고
        state.mainAreaOrder.mainAreaCodeList.splice(0,0,'ADROP_IMAGE_1')

        // 중단 이미지 광고
        state.mainAreaOrder.mainAreaCodeList.splice(2,0,'ADROP_IMAGE_2')
      }
    }
  }
}
