import axios from 'axios'
import cookies from 'js-cookie'
import config from '@/config'

const API_TIMEOUT = 30000 // 30s

function postLBFeedQuizData (endpoint, method, data){
  let aipUrl = config.lbMarketApiBaseUrl + "CommunityPost/PostQuizAnswerSelected"

  return axios({
    url: aipUrl,
    timeout: API_TIMEOUT,
    method: method,
    params: method === 'get' ? data : null,
    data: method === 'post' ? data : null
  })
}

function postLBFeedFilterTotalData (endpoint, method, data){
  let aipUrl = config.lbMarketApiBaseUrl + "Feed/FeedTotalCount"

  return axios({
    url: aipUrl,
    timeout: API_TIMEOUT,
    method: method,
    params: method === 'get' ? data : null,
    data: method === 'post' ? data : null
  })
}


function postLBFeedData (endpoint, method, data){
  let aipUrl = config.lbMarketApiBaseUrl + "Feed/CategoryFeed"

  return axios({
    url: aipUrl,
    timeout: API_TIMEOUT,
    method: method,
    params: method === 'get' ? data : null,
    data: method === 'post' ? data : null,
  })
}

function postLBFeedFollowData (endpoint, method, data){
  let aipUrl = config.lbMarketApiBaseUrl + "Profile/FollowReg"

  return axios({
    url: aipUrl,
    timeout: API_TIMEOUT,
    method: method,
    params: method === 'get' ? data : null,
    data: method === 'post' ? data : null
  })
}


function postLBFeedHighFiveData (endpoint, method, data){
  let aipUrl = config.lbMarketApiBaseUrl + "Feed/HighFiveReg"

  return axios({
    url: aipUrl,
    timeout: API_TIMEOUT,
    method: method,
    params: method === 'get' ? data : null,
    data: method === 'post' ? data : null
  })
}
function postLBFeedFavoriteData (endpoint, method, data){
  let aipUrl = config.lbMarketApiBaseUrl + "Feed/FavoriteReg"

  return axios({
    url: aipUrl,
    timeout: API_TIMEOUT,
    method: method,
    params: method === 'get' ? data : null,
    data: method === 'post' ? data : null
  })
}

function postLBFeedFilterData (endpoint, method, data){
  let aipUrl = config.lbMarketApiBaseUrl + "Feed/Filter"

  return axios({
    url: aipUrl,
    timeout: API_TIMEOUT,
    method: method,
    params: method === 'get' ? data : null,
    data: method === 'post' ? data : null,
  })
}

function getLBData(endpoint, method, data) {
  let aipUrl = config.lbMarketApiBaseUrl + endpoint

  return axios({
    url: aipUrl,
    timeout: API_TIMEOUT,
    method: method,
    params: method === 'get' ? data : null,
    data: method === 'post' ? data : null,
  })
}

function postLBFeedRecentFilterData (endpoint, method, data){
  let aipUrl = config.lbMarketApiBaseUrl + "Feed/RecentFilter" + endpoint

  return axios({
    url: aipUrl,
    timeout: API_TIMEOUT,
    method: method,
    params: method === 'get' ? data : null,
    data: method === 'post' ? data : null
  })
}

function getLBDataWithCatch(endpoint, method, data) {
  let aipUrl = config.lbMarketApiBaseUrl + endpoint

  return axios({
    url: aipUrl,
    timeout: API_TIMEOUT,
    method: method,
    params: method === 'get' ? data : null,
    data: method === 'post' ? data : null,
  }).catch(function (error) {
    return error.response
  })
}

function getNcpData(endpoint, method, data) {
  let aipUrl = config.ncpApiBaseUrl + endpoint

  return axios({
    url: aipUrl,
    timeout: API_TIMEOUT,
    headers: {
      accessToken: cookies.get('ncpAccessToken') || '',
      clientId: config.ncpClientId,
      Version: '1.0',
      platform: config.platform(),
      guestToken: cookies.get('guestToken') || '',
    },
    method: method,
    params: method === 'get' ? data : null,
    data: method === 'post' ? data : null,
  }).catch(function (error) {
    return error.response
  })
}

function getLBDiaryData(endpoint, method, data) {
  let aipUrl = config.lbDiaryApiBaseUrl + endpoint

  return axios({
    url: aipUrl,
    timeout: API_TIMEOUT,
    method: method,
    params: method === 'get' ? data : null,
    data: method === 'post' ? data : null,
  })
}

function getLBCrewData(endpoint, method, data) {
  let aipUrl = config.lbPaycrewBaseUrl + endpoint

  return axios({
    url: aipUrl,
    timeout: API_TIMEOUT,
    method: method,
    params: method === 'get' ? data : null,
    data: method === 'post' ? data : null,
  })
}

function getAdropData(endpoint, method, data) {
  let aipUrl = config.adropUrl + endpoint
  return axios({
    url: aipUrl,
    timeout: API_TIMEOUT,
    headers: {
      Authorization: config.adropId,
    },
    method: method,
    params: method === 'get' ? data : null,
    data: method === 'post' ? data : null,
  }).catch(function (error) {
    return error.response
  })
}
function requestGetWithFullURL(fullurl) {
  let aipUrl = fullurl
  return axios({
    url: aipUrl,
    timeout: API_TIMEOUT,
  }).catch(function (error) {
    return error.response
  })
}

export {
  getLBData,
  getNcpData,
  getLBDataWithCatch,
  getLBDiaryData,
  getLBCrewData,
  postLBFeedData,
  postLBFeedFollowData,
  postLBFeedFavoriteData,
  postLBFeedHighFiveData,
  postLBFeedFilterData,
  postLBFeedRecentFilterData,
  postLBFeedFilterTotalData,
  postLBFeedQuizData,
  getAdropData,
  requestGetWithFullURL
}
