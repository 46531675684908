let sortList = []

sortList.push({
  orderByTarget: 0,
  orderByText: '연관순'
})
sortList.push({
  orderByTarget: 11,
  orderByText: '추천순'
})
/*
sortList.push({
  orderByTarget: 1,
  orderByText: '최신순'
})
*/
sortList.push({
  orderByTarget: 2,
  orderByText: '인기순'
})
sortList.push({
  orderByTarget: 3,
  orderByText: '맛 별점 높은순'
})
sortList.push({
  orderByTarget: 4,
  orderByText: '안질림 별점 높은순'
})
sortList.push({
  orderByTarget: 5,
  orderByText: '1회 제공당 가격 낮은순'
})
sortList.push({
  orderByTarget: 6,
  orderByText: '1회 제공당 가격 높은순'
})
// sortList.push({
//   orderByTarget: 7,
//   orderByText: '1회 제공당 칼로리 낮은순'
// })
// sortList.push({
//   orderByTarget: 8,
//   orderByText: '1회 제공당 당 낮은순'
// })
// sortList.push({
//   orderByTarget: 9,
//   orderByText: '1회 제공당 단백질 높은순'
// })
// sortList.push({
//   orderByTarget: 10,
//   orderByText: '1회 제공당 지방 낮은순'
// })

export default {
  namespaced: true,
  state: {
    sortList: sortList.filter(w => w.orderByTarget !== 0),
    filterSortList: sortList
  }
}
